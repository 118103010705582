import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Electronics',
    paragraph: 'Our team offers consulting and manufacturing services covering all aspects of state of the art electronics design and development.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >            <div class="illustration-section-02" style={{transform: "scaleX(-1)",top:'1550px'}}/>

      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Kymaplex
                  </div>
                <h3 className="mt-0 mb-12">
                Digital
                  </h3>
                <p className="m-0">
                  Our team specialises in Hardware Description Language (HDL)
                  programming of Field Programmable Gate Array (FPGA) devices from the entry point solutions to Ultrascale ones.
                  We can help in all design levels from conceptual design and top level architecture to RTL design and IPcore generation.
                  We take pride in our ability to realise our solutions in high integrity PCBs optimised for signal integrity and timing accuracy through rigorous simulation.
                  We specialise in interfacing high speed ADCs and DACs with any modern serial or parallel communications protocol.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/digital.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Kymaplex
                  </div>
                <h3 className="mt-0 mb-12">
                  Analogue
                  </h3>
                <p className="m-0">
                  We possess deep expertise in developing analogue electronics for sensor interrogation, signal conditioning and powering.
                   Either optimising combinations of COTS ICs or developing custom discrete designs we deliver the best in signal fidelity across the whole transmission or acquisition chain.
                    Our PCB realisation of analogue circuitry focuses on providing top class SNR & crosstalk and designs verified for EMC regulations.
                    We specialise in high frequency and high voltage amplifiers along with Analogue Front Ends (AFE) for high speed ADCs.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/analogue.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Kymaplex
                  </div>
                <h3 className="mt-0 mb-12">
                Enclosures and thermal management
                  </h3>
                <p className="m-0">
                 PCB boards need to survive their intended working environment and our skill in enclosure design and thermal management sets this as top priority.
                 Whether operational temperature, EMC, water/particle ingress (IP rating) and high pressure is of concern or even explosive environment (ATEX) our team has deep expertise
                 in delivering on time and budget. State of the art 3D mechanical CAE tools are utilised to ensure smooth operation across the required operational envelope, whether this pertains
                 to a totally new design or an existing one. We can also undertake testing and certification with partner laboratories and agencies.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/thermal.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Kymaplex
                  </div>
                <h3 className="mt-0 mb-12">
                3D Printing process analysis and optimisation
                  </h3>
                <p className="m-0">
                3D printing is a multi-parametric process. Current technologies utilise not only
a wide range of technologies, ranging from fused deposition modeling to laser
powder bed fusion, but also (multi-)materials with substantially different
thermal and mechanical properties. Whether you are working with bio-degradable
and recycled filaments or with Ti-6Al-4V, we can analyse your designs and
processes and help you reduce printing times, material wastage and
post-processing labour.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/test3.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
        

          <div class="features-split-inner section-inner has-top-divider"/>
          
      </div>



    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
